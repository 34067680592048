import { Input, MenuItem, Select } from "@material-ui/core";
import { Link } from "react-router-dom";
import FilterDateRange from "../../../components/System/FilterDateRange/FilterDateRange";
import { checkDateRange, editComponent, sortByDate, editTextarea } from "../helperTables";
import React from "react";
import FilterInput from "../../../components/System/FilterInput/FilterInput";

export default {
  designerStatusColumns: [
    { title: "ID", field: "id", hidden: true },
    {
      title: "Accepted",
      field: "accepted",
      editable: "never",
    },
    {
      title: "Designer",
      field: "designer",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    /* Product */ {
      title: "Style name",
      field: "style_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      headerStyle: {
        paddingRight: "50px",
      },
    },
    /* Product */ {
      title: "Index",
      field: "index",
      editable: "never",
      defaultFilter: "all",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return (
          // <Select
          //   value={term.columnDef.tableData.filterValue}
          //   onChange={(e) => {
          //     term.columnDef.tableData.filterValue = e.target.value;
          //     term.onFilterChanged(term.columnDef.tableData.id, e.target.value);
          //   }}
          // >
          //   <MenuItem value="all">All</MenuItem>
          //   <MenuItem value="unadded">Unadded</MenuItem>
          //   <MenuItem value="added">Added</MenuItem>
          // </Select>
          <FilterInput term={term} onChange={onChange} />
        );
      },
      customFilterAndSearch: (term, rowData) =>
        term === "all"
          ? rowData
          : term === "unadded"
          ? rowData.index === null
          : term === "added"
          ? rowData.index !== null
          : rowData.index !== null
          ? rowData.index.includes(term)
          : null,
    },
    {
      title: "Order number",
      field: "order_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      render: (rowData) => {
        return (
          <div>
            <Link to={"/orders/" + rowData.order_id}>{rowData.order_number}</Link>
          </div>
        );
      },
    },
    /* Order */
    {
      title: "Shipment per order",
      field: "shipment_date",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipment_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "shipment_date"),
    },
    /* Order */ {
      title: "Shipper name",
      field: "contact_name",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    /* Product */ {
      title: "Quantity per order",
      field: "quantity",
      editable: "never",
      type: "number",
      render: (rowData) => <div className={"amountPercentage"}>{rowData.quantity}</div>,
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseInt(a.quantity.replace(" ", "")) - parseInt(b.quantity.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "0px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    /* Product */ {
      title: "Price (USD) per order",
      field: "price",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      customSort: (a, b) => parseFloat(a.price.replace(" ", "")) - parseFloat(b.price.replace(" ", "")),
      render: (rowData) => <div className={"pricePerOrder"}>{rowData.price}</div>,
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "0px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Date of production approval",
      field: "production_approval",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Movie received",
      field: "movie_received",
      type: "boolean",
    },
    {
      title: "Index check date",
      field: "index_check_date",
      editable: "never",
      type: "dateType",
      editComponent: (props) => editComponent(props, true),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.index_check_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "index_check_date"),
    },
    {
      title: "Final product confirm date",
      field: "sample_confirm_date",
      editable: "never",
      type: "dateType",
      editComponent: (props) => editComponent(props, false),
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.sample_confirm_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "sample_confirm_date"),
    },
    {
      title: "Discount",
      field: "discount",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "ETD",
      field: "etd",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.etd);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "etd"),
    },
    {
      title: "ETA",
      field: "eta",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.eta);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "eta"),
    },
    {
      title: "Warehouse",
      field: "warehouse",
      editable: "never",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.warehouse);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "warehouse"),
    },
    {
      title: "DATE OF SENDING TO STORES",
      field: "shipping_date_to_stores",
      type: "dateType",
      editable: "never",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.shipping_date_to_stores);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "shipping_date_to_stores"),
    },
    {
      title: "Check date at store",
      field: "check_date",
      type: "dateType",
      filterComponent: (term) => {
        const onChange = (dataRange) => term.onFilterChanged(term.columnDef.tableData.id, dataRange);
        return <FilterDateRange onChange={onChange} />;
      },
      customFilterAndSearch: (term, rowData) => {
        return checkDateRange(term, rowData.check_date);
      },
      customSort: (a, b, c, d) => sortByDate(a, b, d, "check_date"),
    },
    {
      title: "Designer comment",
      field: "designer_comment",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editTextarea(props, false),
    },
    {
      title: "Comments",
      field: "comments",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Claim",
      field: "claim",
      type: "boolean",
    },
    {
      title: "Claim content",
      field: "claim_content",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editTextarea(props, false),
    },
    /* Ukryte kolumny */
    /* Product */ {
      title: "Sex",
      field: "sex",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    /* Product */ {
      title: "Fabric",
      field: "fabric",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    /* Product */ {
      title: "Description",
      field: "description",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
    {
      title: "Quantity as per commercial invoice",
      field: "quantity_invoice",
      editable: "never",
      type: "number",
      render: (rowData) => <div className={"amountPercentage"}>{rowData.quantity_invoice}</div>,
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) =>
        parseInt(a.quantity_invoice.replace(" ", "")) - parseInt(b.quantity_invoice.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    {
      title: "Price as per commercial invoice",
      field: "price_invoice",
      editable: "never",
      type: "number",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
      editComponent: (props) => editComponent(props, false),
      customSort: (a, b) => parseFloat(a.price_invoice.replace(" ", "")) - parseFloat(b.price_invoice.replace(" ", "")),
      headerStyle: {
        textAlign: "right",
        paddingRight: "0",
        paddingLeft: "50px",
      },
      cellStyle: {
        textAlign: "right",
      },
      filterCellStyle: {
        paddingRight: "0",
      },
    },
    /* Order */ {
      title: "LC number",
      field: "lc_number",
      editable: "never",
      type: "text",
      filterComponent: (term) => {
        const onChange = (value) => term.onFilterChanged(term.columnDef.tableData.id, value);
        return <FilterInput term={term} onChange={onChange} />;
      },
    },
  ],
};
