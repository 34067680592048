import React, { Component } from "react";
import axios from "../../axios-sw";
import { connect } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import MaterialTable, {
  MTableEditRow,
  MTableToolbar,
  MTableBodyRow,
  MTableFilterRow,
  MTableBody,
} from "material-table";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  Input,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import ReactSelect from "react-select";
import moment from "moment";
import {
  OpenInNew,
  PictureAsPdf,
  Cancel,
  HighlightOff,
  GetApp,
  WrapText,
  Check,
  Close,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import { Helmet } from "react-helmet";

import { titleTemplate, tableIcons } from "../../shared/tableVariables";
import { role } from "../../config";

import Columns from "../../shared/tables";
import OrderProducts from "./OrderProducts/OrderProducts";
import { HotKeys } from "../../components/System/HotKeys/HotKeys";

import {
  authRedirect,
  thenResponse,
  catchResponse,
  indexDetails,
  nonAdminRedirect,
  formatDate,
  getAndConvertULRParams,
  getSortPath,
  getFilterPath,
  createSortPath,
  createSingleColumnFilterVal,
  sendEmailAboutLoadingDelay,
} from "../../shared/utility";

import Spinner from "../../components/UI/Spinner/Spinner";
import { defaultPageSize } from "../../shared/defines";
import { json2excel } from "js2excel";
import Modal from "../../components/UI/Modal/Modal";
import classes from "../Bookings/Bookings.module.scss";
import SearchInput from "../../components/UI/SearchInput/SearchInput";
import ColumnsMultiSelect from "../../components/System/ColumnsMultiSelect/ColumnsMultiSelect";
import ProgressCircular from "../../components/UI/ProgressCircular/ProgressCircular";

import { Pagination } from "../../components/table";

const formatterQuantity = new Intl.NumberFormat("pl-PL");

const formatterCurrency = new Intl.NumberFormat("pl-PL", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const sortingPhraseHelper = [
  { key: "accepted", path: "orders.accepted" },
  { key: "number", path: "orders.number" },
  { key: "contact", path: "contacts.name" },
  { key: "company", path: "contacts.company" },
  { key: "designer_code", path: "designers.code" },
  { key: "season_id", path: "seasons.code" },
  { key: "port", path: "ports.code" },
  { key: "shipment_date", path: "orders.shipment_date" },
  { key: "country", path: "orders.country" },
  { key: "payment_term_id", path: "orders.payment_term_id" },
  { key: "lc_number", path: "orders.lc_number" },
  {
    key: "total_quantity",
    path: "(SELECT SUM(`combinations`.`quantity`) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id))",
  },
  {
    key: "total_value",
    path: "(SELECT SUM(ROUND(price, 2)  * quantity) FROM `combinations` INNER JOIN `products` ON `products`.`id` = `combinations`.`product_id` WHERE (products.order_id = orders.id))",
  },
  { key: "final_order", path: "orders.final_order" },
  { key: "proforma_document", path: "orders.proforma_document" },
  { key: "barcodes_in_process", path: "orders.barcodes_in_process" },
];

class Orders extends Component {
  confirmDialog = (msg) => {
    return new Promise(function (resolve, reject) {
      let confirmed = window.confirm(msg);
      return confirmed ? resolve(true) : reject(false);
    });
  };

  state = {
    actions: [],
    editable: {},
    columns: Columns.ordersColumns,
    columnsHidden: Columns.ordersColumns,
    currentStateColumns: null,
    data: [],
    filteredData: [],
    totalQuantity: 0,
    quantityPercentage: 0,
    totalValue: 0,
    valuePercentage: 0,
    totalRow: 0,
    totalFinalOrders: 0,
    filterPhrase: "",
    sortBy: null,
    order: null,
    sortingColumnsList: [],
    singleColumnFilterList: [],
    firstLoad: true,
    tableRef: React.createRef(),
    exportChosen: false,
    ports: [],
    contacts: [],
    seasons: [],
    order_number: React.createRef(),
    season: null,
    designer: null,
    contact: null,
    designers: [],
    loaded: false,
    isModal: false,
    rowData: null,
    repeatOrder: false,
    blockedSaveColumnsButton: true,
  };

  async componentDidMount() {
    const { page, limit, search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
      this.props.location.search,
      sortingPhraseHelper
    );

    const localStorageLimit = localStorage.getItem(`${this.constructor.name}Limit`);
    if (localStorageLimit === null) {
      this.indexOrders(page - 1, limit, search, sortingColumnsList, singleColumnFilterList);
    } else {
      this.setState({ limit: parseInt(localStorageLimit) });
      this.indexOrders(page - 1, localStorageLimit, search, sortingColumnsList, singleColumnFilterList);
    }

    indexDetails("seasons", 5, "code", this.props.token, this.state.columns, (newState) => this.setState({ newState }));
    this.indexPorts();
    indexDetails("payment_terms", 9, "code", this.props.token, this.state.columns, (newState) =>
      this.setState({ newState })
    );
    this.indexSeasons();
    this.indexContacts();
    this.indexDesigners();
    this.checkActionsAndEditablePermissions();

    await this.getFilterColumns();
    this.setSortingForColumns();

    const table = this.state.tableRef.current;
    if (table !== null) {
      singleColumnFilterList.forEach((filter) => {
        const index = this.state.tableRef.current.state.columns.findIndex(
          (column) => column.field === filter.column.key
        );
        if (filter.type === "boolean") {
          if (filter.value.includes("true")) this.state.tableRef.current.onFilterChange(index, "checked");
          else if (filter.value.includes("false")) this.state.tableRef.current.onFilterChange(index, undefined);
          else this.state.tableRef.current.onFilterChange(index, null);
        } else if (filter.type === "text") {
          const value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "").trim();
          this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
        } else if (filter.type === "number") {
          const value = filter.value.replace("=", "").trim();
          this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
        } else if (filter.type === "select") {
          /*WYJĄTKI*/
          if (filter.column.key === "proforma_document" && filter.value === `="" OR ${filter.column.path} IS NULL`)
            table.onFilterChange(index, "unadded");
          if (
            filter.column.key === "proforma_document" &&
            filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
          )
            table.onFilterChange(index, "added");
        } else if (filter.type === "multiSelect") {
          if (filter.column.key === "accepted") {
            let value = [];
            if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`))
              value = [...value, "all"];
            if (filter.column.key === "accepted" && filter.value.includes(`="" OR ${filter.column.path} IS NULL`))
              value = [...value, "empty"];
            if (
              filter.column.key === "accepted" &&
              filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
            )
              value = [...value, "accepted"];
            if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value = [...value, "hold"];
            if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
              value = [...value, "cancel"];
            this.state.tableRef.current.state.columns[index].tableData.initialValue = value;
          } else {
            let value = filter.value
              .split("LIKE")
              .join("")
              .split("'%")
              .join("")
              .split("%'")
              .join("")
              .split(`OR ${filter.column.path}`)
              .join(",")
              .trim();
            let valueArray = value.split(",").map((item) => item.trim());
            table.onFilterChange(index, valueArray);
          }
        }
      });
      if (singleColumnFilterList.filter((filter) => filter.type === "boolean").length === 0) {
        for (let i = 0; i < table.state.columns.length; i++) {
          if (table.state.columns[i].type !== "select" && table.state.columns[i].type !== "multiSelect") {
            table.onFilterChange(i, null);
            table.state.columns[i].tableData.filterValue = null;
          }
        }
      }
    }
  }

  getFilterColumns = () => {
    const { currentStateColumns } = this.state;
    const columnSettings = JSON.parse(window.localStorage.getItem(`user_orders_${this.props.user_id}`));
    let columnsHidden = [];

    if (currentStateColumns) {
      columnsHidden = currentStateColumns;
    } else if (columnSettings && !currentStateColumns) {
      columnSettings.forEach((column) => {
        this.state.columns.forEach((item) => {
          if (column.field === item.field) {
            columnsHidden = [...columnsHidden, item];
          }
        });
      });
    } else {
      columnsHidden = this.state.columns;
    }
    this.setState({ columnsHidden: columnsHidden });
  };

  setSortingForColumns = () => {
    const sortLabelsDOM = document.querySelectorAll(".MuiTableSortLabel-root");
    sortLabelsDOM.forEach((label) => {
      label.addEventListener("click", (e) => {
        let sortingColumnsList = this.state.sortingColumnsList;
        const labelName = label.querySelector("div").innerHTML.trim();
        const labelField = this.state.columns.find((column) => column.title === labelName).field;

        let indexIsExist = this.state.sortingColumnsList.findIndex((column) => column.sortBy.key === labelField);

        if (indexIsExist !== -1) {
          if (sortingColumnsList[indexIsExist].order === "DESC") sortingColumnsList.splice(indexIsExist, 1);
          else if (sortingColumnsList[indexIsExist].order === "ASC") sortingColumnsList[indexIsExist].order = "DESC";
          else if (!sortingColumnsList[indexIsExist].order) sortingColumnsList[indexIsExist].order = "ASC";
          this.setState({ sortingColumnsList: sortingColumnsList });
        } else {
          const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === labelField);
          sortingColumnsList = [...sortingColumnsList, { sortBy: sqlColumn[0], order: "ASC" }];
          this.setState({ sortingColumnsList: sortingColumnsList });
        }

        this.goPage(
          0,
          this.state.limit,
          this.state.filterPhrase,
          sortingColumnsList,
          this.state.singleColumnFilterList
        );
      });
    });
  };

  setSelectedColumns = (columns) => {
    let selectedColumns = [];

    columns.forEach((column) => {
      this.state.columns.forEach((item) => {
        if (column.value === item.field) {
          selectedColumns = [...selectedColumns, item];
        }
      });
    });

    this.setState({
      columnsHidden: selectedColumns,
      currentStateColumns: selectedColumns,
    });
  };

  handleSerach = (val) => {
    let convertVal = val.split("'").join("");

    const table = this.state.tableRef.current;
    const singleColumnFilterList = [];
    table.state.columns.forEach((column) => {
      column.tableData.initialValue = "";
    });
    this.goPage(0, this.state.limit, convertVal.toUpperCase(), this.state.sortingColumnsList, singleColumnFilterList);
    this.setState({ filterPhrase: convertVal.toUpperCase(), singleColumnFilterList: singleColumnFilterList });
  };

  goPage = (page = 0, limit = 20, search = null, sortingColumnsList = [], singleColumnFilterList = []) => {
    this.setState({ loaded: false, filteredData: [], data: [] });
    const searchVal = search && search.trim().length > 0 ? `&search=${search}` : "";

    const sortByVal = createSortPath(sortingColumnsList);
    const singleColumnFilterVal = createSingleColumnFilterVal(singleColumnFilterList);

    this.props.history.push(`/orders?page=${page + 1}&limit=${limit}${searchVal}${sortByVal}${singleColumnFilterVal}`);
    this.indexOrders(page, limit, search, sortingColumnsList, singleColumnFilterList, this.state.currentStateColumns);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentSeasons !== prevProps.currentSeasons) {
      this.setState({ data: [], filteredData: [], loaded: false });
      this.indexOrders(
        0,
        this.state.limit,
        this.state.filterPhrase,
        this.state.sortingColumnsList,
        this.state.singleColumnFilterList
      );
    }
    if (this.props.role !== prevProps.role) {
      this.checkActionsAndEditablePermissions();
    }
  }

  indexOrders = (
    page = 1,
    limit = 20,
    phrase = null,
    sortingColumnsList = [],
    singleColumnFilterList = [],
    currentStateColumns = null
  ) => {
    const sortPath = getSortPath(sortingColumnsList);
    const filterPath = getFilterPath(singleColumnFilterList);
    const currentSeasons = this.props.currentSeasons.map((season) => season.value);

    const startTime = Date.now();

    console.log(localStorage.getItem("token"));

    axios
      .get(`/orders/${page}/${limit}.json`, {
        params: {
          phrase: phrase,
          sortPath: sortPath,
          filterPath: filterPath,
          currentSeasons: currentSeasons,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, "Content-Type": "application/json" },
      })
      .then((res) => {
        this.state.tableRef.current.state.orderBy = -1;
        this.state.tableRef.current.state.orderDirection = "";
        this.state.tableRef.current.dataManager.orderBy = -1;
        this.state.tableRef.current.dataManager.orderDirection = "";

        const responseTime = (Date.now() - startTime) / 1000;
        console.log("Response time: " + responseTime + " s");
        const columnSettings = JSON.parse(window.localStorage.getItem(`user_orders_${this.props.user_id}`));
        let columnsHidden = [];

        if (currentStateColumns) {
          columnsHidden = currentStateColumns;
        } else if (columnSettings && !currentStateColumns) {
          columnSettings.forEach((column) => {
            this.state.columns.forEach((item) => {
              if (column.field === item.field) {
                columnsHidden = [...columnsHidden, item];
              }
            });
          });
        } else {
          columnsHidden = this.state.columns;
        }

        this.setState({
          data: res.data.orders,
          totalRow: res.data.total_row,
          totalQuantity: res.data.total_quantity,
          totalValue: parseFloat(res.data.total_value),
          totalFinalOrders: res.data.total_final_orders,
          quantityPercentage: res.data.total_quantity_percentage,
          valuePercentage: res.data.total_value_percentage,
          page: parseInt(page),
          limit: parseInt(limit),
          filterPhrase: phrase,
          sortingColumnsList: sortingColumnsList,
          singleColumnFilterList: singleColumnFilterList,
          filteredData: res.data.orders,
          columnsHidden: columnsHidden,
          loaded: true,
        });

        if (responseTime > 10) {
          let ids = "";
          res.data.orders.forEach((order) => (ids += `${order.id}, `));
          sendEmailAboutLoadingDelay(
            "Orders",
            this.props.user_id,
            "Response time: " + responseTime + " s",
            phrase,
            sortPath,
            filterPath,
            currentSeasons,
            page,
            limit,
            ids,
            this.props.token
          );
        }
      })
      .catch((err) => {
        this.setState({ loaded: true });
        catchResponse(err);
      });
  };
  indexPorts = () => {
    axios
      .get("/ports.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        let map = new Map();
        let localMap = new Map();
        for (var i = 0; i < res.data.length; i++) {
          map.set(res.data[i]["code"], res.data[i]["code"]);
          localMap.set(res.data[i]["id"], res.data[i]["code"]);
        }
        let obj = Object.fromEntries(map);
        let localObj = Object.fromEntries(localMap);
        let newState = { ...this.state.columns };
        const portIndex = this.state.columns.findIndex((column) => column.field === "port");
        newState[portIndex].lookup = obj;
        this.setState({ columns: Object.values(newState), ports: localObj });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexSeasons = () => {
    axios
      .get("/seasons.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ seasons: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexDesigners = () => {
    axios
      .get("/designers.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ designers: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  indexContacts = () => {
    axios
      .get("/contacts.json", { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        const result = res.data.map(function (obj) {
          return { value: obj.id, label: obj.code };
        });
        this.setState({ contacts: result });
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  updateOrder = (id, old_lc_number, newData) => {
    let port_id = null;
    Object.keys(this.state.ports).forEach((key) => {
      if (this.state.ports[key] === newData.port) {
        port_id = key;
      }
    });
    const data = {
      order: {
        accepted: newData.accepted == null ? null : newData.accepted.toUpperCase(),
        number: newData.number == null ? null : newData.number.toUpperCase(),
        season_id: newData.season_id,
        port_id: port_id,
        shipment_date: newData.shipment_date,
        country: newData.country,
        payment_term_id: newData.payment_term_id,
        lc_number:
          newData.lc_number === ""
            ? null
            : newData.lc_number == null
            ? null
            : newData.lc_number.replace(/\s/g, "").toUpperCase(),
        final_order: newData.final_order,
        barcodes_in_process: newData.barcodes_in_process,
      },
      /* Dla automatycznego tworzenia LCs */
      lc_number: newData.lc_number == null ? null : newData.lc_number.replace(/\s/g, "").toUpperCase(),
      old_lc_number: old_lc_number == null ? null : old_lc_number.replace(/\s/g, "").toUpperCase(),
      /* Activity logging */
      user_id: this.props.user_id,
      ip_address: this.props.ip_address,
    };
    const url = "/orders/" + id + ".json";
    axios
      .put(url, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
      .then((res) => {
        axios
          .get(url, { headers: { Authorization: `Bearer ${this.props.token}` } })
          .then((res) => {
            const fetchedOrder = res.data;
            const array = [...this.state.data];
            const index = array.findIndex((x) => x.id === fetchedOrder.id);
            if (index !== -1) {
              array[index].accepted = fetchedOrder.accepted;
              array[index].number = fetchedOrder.number;
              array[index].season_id = fetchedOrder.season_id;
              array[index].port = fetchedOrder.port;
              array[index].shipment_date = fetchedOrder.shipment_date;
              array[index].country = fetchedOrder.country;
              array[index].payment_term_id = fetchedOrder.payment_term_id;
              array[index].lc_number = fetchedOrder.lc_number;
              array[index].final_order = fetchedOrder.final_order;
              array[index].proforma_document = fetchedOrder.proforma_document;
              array[index].barcodes_in_process = fetchedOrder.barcodes_in_process;
              array[index].styles = fetchedOrder.styles;
              this.setState({ data: array });
            }
          })
          .catch((err) => {
            catchResponse(err);
          });
        thenResponse(res);
      })
      .catch((err) => {
        catchResponse(err);
      });
  };

  isModalHandler = () => {
    this.setState({ isModal: !this.state.isModal, rowData: null });
  };

  cloneOrder = () => {
    this.setState({ isModal: false, loaded: false });
    let { rowData, season, designer, contact, order_number, repeatOrder, loaded } = this.state;
    const { user_id, ip_address } = this.props;

    const array = [...this.state.data];
    const index = array.findIndex((x) => x.id === rowData.id);
    if (index !== -1) {
      // Combination clone logic
      const cloned_element = _.cloneDeep(array[index]);
      cloned_element.id = (Math.random() * 1e6) | 1;
      array.push(cloned_element);

      const data = {
        order: cloned_element,
        seasonId: season.value,
        contactId: contact.value,
        seasonCode: repeatOrder ? `R${season.label}` : season.label,
        contactCode: contact.label,
        repeat_order: repeatOrder,
        user_id: user_id,
        ip_address: ip_address,
      };

      const number = repeatOrder
        ? `R${season.label}${designer.label}-${contact.label}${order_number.current.value}`
        : `${season.label}${designer.label}-${contact.label}${order_number.current.value}`;
      const definedPartNew = repeatOrder
        ? `${designer.label}-${contact.label}R${season.label}`
        : `${designer.label}-${contact.label}${season.label}`;
      const definedPartOld = `${cloned_element.designer_code}-${cloned_element.contact_code}${cloned_element.season_code}`;
      const orderNumber = parseInt(order_number.current.value);

      if (definedPartNew.trim() !== definedPartOld.trim() && !isNaN(orderNumber)) {
        axios
          .post(`/orders/clone-order/${rowData.id}/${number}.json`, data, {
            headers: { Authorization: `Bearer ${this.props.token}` },
          })
          .then((res) => {
            this.indexOrders();
            this.setState({ loaded: true });
            thenResponse(res);
          })
          .catch((err) => {
            catchResponse(err);
            this.setState({ loaded: true });
          });
      } else {
        toast.error("Wrong order number!");
        this.setState({ loaded: true });
      }
    } else {
      this.setState({ loaded: true });
    }
  };

  checkActionsAndEditablePermissions = () => {
    if (this.props.role === role.MANAGEMENT) {
      this.setState({
        actions: [
          {
            icon: PictureAsPdf,
            tooltip: "Show order pdf preview",
            onClick: (event, rowData) => {
              this.props.history.push("/orders/" + rowData.id + "/pdf-preview");
            },
          },
        ],
        editable: {},
      });
    } else {
      this.setState({
        actions: [
          () => ({
            icon: OpenInNew,
            tooltip: "Order details",
            onClick: (event, rowData) => {
              this.props.history.push("/orders/" + rowData.id);
            },
          }),
          {
            icon: PictureAsPdf,
            tooltip: "Show order pdf preview",
            onClick: (event, rowData) => {
              this.props.history.push("/orders/" + rowData.id + "/pdf-preview");
            },
          },
          {
            icon: Cancel,
            tooltip: "Cancel order",
            // Disabled for Marta Matuszewska (user_id = 55), ultimately it should be if rowData.accepted !== null
            // disabled: this.props.user_id === "55",
            onClick: (event, rowData) => {
              this.confirmDialog("You want to cancel order " + rowData.number + "?").then(() => {
                const data = {
                  order: {
                    cancelled: true,
                    number: rowData.number,
                    season_id: rowData.season_id,
                  },
                  /* Activity logging */
                  user_id: this.props.user_id,
                  ip_address: this.props.ip_address,
                };
                axios
                  .put("/orders/" + rowData.id, data, { headers: { Authorization: `Bearer ${this.props.token}` } })
                  .then((res) => {
                    const array = [...this.state.data];
                    const index = array.findIndex((x) => x.id === rowData.id);
                    array.splice(index, 1);
                    this.setState({ data: array, filteredData: array });
                    thenResponse(res);
                  })
                  .catch((err) => {
                    catchResponse(err);
                  });
              });
            },
          },
          () => ({
            icon: WrapText,
            tooltip: "Clone order",
            onClick: (event, rowData) => {
              this.setState({
                isModal: true,
                rowData: rowData,
                season: {
                  value: rowData.season_id,
                  label: rowData.season_code,
                },
                contact: {
                  value: rowData.contact_id,
                  label: rowData.contact_code,
                },
                designer: {
                  value: rowData.designer_id,
                  label: rowData.designer_code,
                },
              });
            },
          }),
        ],
        editable: {
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              resolve();
              this.updateOrder(oldData.id, oldData.lc_number, newData);
            }),
        },
      });
    }
  };

  render() {
    let emptyDataMessage;

    if (!this.state.loaded) {
      emptyDataMessage = <Spinner />;
    } else {
      emptyDataMessage = "No data to display";
    }

    return (
      <>
        <Helmet>
          <title>Orders | Carry System</title>
        </Helmet>

        {!this.state.loaded && <div className={"overlay"}></div>}
        {authRedirect(this.props.token)}
        {nonAdminRedirect(this.props.user_id, this.props.role)}
        <ToastContainer />
        <HotKeys />
        {this.state.isModal && (
          <Modal show={this.state.isModal} modalClosed={this.isModalHandler}>
            <div className={classes.WrapperModal}>
              <p>{`Order number: ${this.state.rowData.number}`}</p>
              <FormControl className={classes.FormControlStyleWithSelect}>
                <p className={classes.Label}>Order number</p>
                <div className={classes.Wrapper}>
                  <div className={classes.ReactSelect}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ season: e });
                      }}
                      value={this.state.season}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.seasons}
                    />
                  </div>
                  <div className={classes.ReactSelect}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ designer: e });
                      }}
                      value={this.state.designer}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.designers}
                    />
                  </div>
                  <div className={classes.ReactSelectContact}>
                    <ReactSelect
                      placeholder=""
                      onChange={(e) => {
                        this.setState({ contact: e });
                      }}
                      value={this.state.contact}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 8000 }) }}
                      options={this.state.contacts}
                    />
                  </div>
                  <div className={classes.StyleInput}>
                    <Input placeholder={"23"} inputRef={this.state.order_number} />
                  </div>
                </div>
                <div className={classes.checkboxWrapper}>
                  <Checkbox
                    checked={this.state.repeatOrder}
                    size={"small"}
                    onChange={() => this.setState({ repeatOrder: true })}
                    color="primary"
                  />
                  <span>Repeat order</span>
                </div>
              </FormControl>
              <Button variant="contained" color="primary" className={classes.ModalButton} onClick={this.cloneOrder}>
                Clone Order
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className={classes.ModalButton}
                onClick={this.isModalHandler}
              >
                Close
              </Button>
            </div>
          </Modal>
        )}

        <MaterialTable
          tableRef={this.state.tableRef}
          title={titleTemplate("Orders")}
          localization={{
            body: {
              emptyDataSourceMessage: emptyDataMessage,
              editRow: {
                cancelTooltip: "Back",
              },
            },
          }}
          icons={tableIcons}
          columns={this.state.columnsHidden}
          data={this.state.filteredData}
          actions={this.state.actions}
          style={{ width: "100%" }}
          isLoading={!this.state.loaded}
          options={{
            search: false,
            pageSize: defaultPageSize,
            filtering: true,
          }}
          detailPanel={(rowData) => {
            return (
              <div className="detailPanel">
                <OrderProducts
                  orderNumber={rowData.number}
                  orderId={rowData.id}
                  user_id={this.props.user_id}
                  ip_address={this.props.ip_address}
                  role={this.props.role}
                  token={this.props.token}
                />
              </div>
            );
          }}
          onChangeRowsPerPage={(pages) => {
            this.setState({ limit: pages, loaded: false, data: [], filteredData: [] });
            localStorage.setItem(`${this.constructor.name}Limit`, pages);
            this.indexOrders(
              0,
              pages,
              this.state.filterPhrase,
              this.state.sortingColumnsList,
              this.state.singleColumnFilterList
            );
          }}
          components={{
            Body: (props) => {
              return (
                <MTableBody
                  {...props}
                  onFilterChanged={(columnId, value) => {
                    const columnName = this.state.columnsHidden[columnId].field;
                    let singleColumnFilterList = this.state.singleColumnFilterList;
                    let indexIsExist = this.state.singleColumnFilterList.findIndex(
                      (filter) => filter.column.key === columnName
                    );
                    const sqlColumn = sortingPhraseHelper.filter((phrase) => phrase.key === columnName);
                    const table = this.state.tableRef.current;

                    if (this.state.columnsHidden[columnId].type === "dateType") {
                      if (indexIsExist !== -1) {
                        if (value.isEmpty) {
                          singleColumnFilterList[indexIsExist].value = "IS NULL";
                        } else if (value.isFull) {
                          singleColumnFilterList[indexIsExist].value = "IS NOT NULL";
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList[indexIsExist].value = `BETWEEN "${start}" AND "${end}"`;
                        }
                      } else {
                        if (value.isEmpty) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NULL",
                              type: "dateType",
                            },
                          ];
                        } else if (value.isFull) {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: "IS NOT NULL",
                              type: "dateType",
                            },
                          ];
                        } else {
                          const start = moment(value.start).format("YYYY-MM-DD");
                          const end = moment(value.end).format("YYYY-MM-DD");
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `BETWEEN "${start}" AND "${end}"`,
                              type: "dateType",
                            },
                          ];
                        }
                      }
                    } else if (this.state.columnsHidden[columnId].type === "text") {
                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `LIKE '%${value}%'`;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `LIKE '%${value}%'`,
                            type: "text",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "boolean") {
                      if (indexIsExist !== -1) {
                        if (value === "checked") {
                          singleColumnFilterList.splice(indexIsExist, 1);
                          table.onFilterChange(columnId, null);
                        } else if (value === "unchecked") {
                          singleColumnFilterList[indexIsExist].value = `=false OR ${sqlColumn[0].path} IS NULL`;
                          table.onFilterChange(columnId, undefined);
                        } else if (!value) {
                          singleColumnFilterList[indexIsExist].value = "=true";
                          table.onFilterChange(columnId, "checked");
                        }
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: "=true",
                            type: "boolean",
                          },
                        ];
                        table.onFilterChange(columnId, "checked");
                      }
                    } else if (this.state.columnsHidden[columnId].type === "number") {
                      const convertValue = value ? parseFloat(value.replace(/\s/g, "").replace(",", ".")) : 0;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = `= ${convertValue}`;
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: `= ${convertValue}`,
                            type: "number",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = convertValue;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "select") {
                      if (value === "all") value = `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "empty" || value === "unadded") value = `="" OR ${sqlColumn[0].path} IS NULL`;
                      else if (value === "accepted" || value === "added")
                        value = `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                      else value = `LIKE "%${value}%"`;

                      if (indexIsExist !== -1) {
                        singleColumnFilterList[indexIsExist].value = value;
                        table.state.columns[columnId].tableData.initialValue = value;
                      } else {
                        singleColumnFilterList = [
                          ...singleColumnFilterList,
                          {
                            column: sqlColumn[0],
                            value: value,
                            type: "select",
                          },
                        ];
                        table.state.columns[columnId].tableData.initialValue = value;
                      }
                    } else if (this.state.columnsHidden[columnId].type === "multiSelect") {
                      if (this.state.columnsHidden[columnId].field === "accepted") {
                        let convertValue = "";

                        if (value.length > 0) {
                          value.forEach((item, index) => {
                            if (item === "all")
                              convertValue +=
                                index < value.length - 1
                                  ? `LIKE "%%" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                  : `LIKE "%%" OR ${sqlColumn[0].path} IS NULL`;
                            else if (item === "empty" || item === "unadded")
                              convertValue +=
                                index < value.length - 1
                                  ? ` ="" OR ${sqlColumn[0].path} IS NULL OR ${sqlColumn[0].path} `
                                  : `="" OR ${sqlColumn[0].path} IS NULL`;
                            else if (item === "accepted" || item === "added")
                              convertValue +=
                                index < value.length - 1
                                  ? `OR ${sqlColumn[0].path} <> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path} OR ${sqlColumn[0].path} `
                                  : `<> '' AND ${sqlColumn[0].path} IS NOT NULL AND ${sqlColumn[0].path}`;
                            else
                              convertValue +=
                                index < value.length - 1
                                  ? `LIKE "%${item}%" OR ${sqlColumn[0].path} `
                                  : `LIKE "%${item}%"`;
                          });

                          if (indexIsExist !== -1) {
                            singleColumnFilterList[indexIsExist].value = convertValue;
                            table.state.columns[columnId].tableData.initialValue = value;
                          } else {
                            singleColumnFilterList = [
                              ...singleColumnFilterList,
                              {
                                column: sqlColumn[0],
                                value: convertValue,
                                type: "multiSelect",
                              },
                            ];
                            table.state.columns[columnId].tableData.initialValue = value;
                          }
                        } else {
                          singleColumnFilterList = singleColumnFilterList.filter(
                            (filter) => filter.column.key !== "accepted"
                          );
                          table.state.columns[columnId].tableData.initialValue = ["all"];
                        }
                      } else {
                        if (indexIsExist !== -1) {
                          let multiFiltersValues = "";
                          value.forEach((filter, index) => {
                            multiFiltersValues +=
                              index === 0
                                ? `LIKE '%${value[index]}%'`
                                : ` OR ${sqlColumn[0].path} LIKE '%${value[index]}%'`;
                          });
                          singleColumnFilterList[indexIsExist].value = multiFiltersValues;
                          table.onFilterChange(columnId, value);
                        } else {
                          singleColumnFilterList = [
                            ...singleColumnFilterList,
                            {
                              column: sqlColumn[0],
                              value: `LIKE '%${value}%'`,
                              type: "multiSelect",
                            },
                          ];
                          table.onFilterChange(columnId, value);
                        }
                      }
                    }
                    this.setState({ singleColumnFilterList: singleColumnFilterList, filterPhrase: "" });
                    this.goPage(0, this.state.limit, "", this.state.sortingColumnsList, singleColumnFilterList);
                  }}
                />
              );
            },
            Pagination: (props) => (
              <Pagination
                props={props}
                limit={this.state.limit}
                totalRows={this.state.totalRow}
                page={this.state.page}
                goPageCallback={(page, limit) =>
                  this.goPage(
                    page,
                    limit,
                    this.state.filterPhrase,
                    this.state.sortingColumnsList,
                    this.state.singleColumnFilterList
                  )
                }
                setState={(state) => this.setState(state)}
                tableRef={this.state.tableRef}
              />
            ),
            FilterRow: (props) => {
              return <MTableFilterRow className="filterRow" {...props} />;
            },
            Row: (props) => <MTableBodyRow className={props.data.styles} {...props} />,
            EditRow: (props) => <MTableEditRow className="editingRow uppercase" {...props} />,
            Toolbar: (props) => {
              const table = this.state.tableRef.current;

              return (
                <div className="mainToolbar">
                  <MTableToolbar {...props} />
                  <Chip
                    variant="outlined"
                    style={{ marginLeft: 24, marginTop: 5 }}
                    avatar={<HighlightOff />}
                    label="Clear filters"
                    onClick={() => {
                      this.goPage();
                      this.props.history.push(`/orders?page=${1}&limit=${20}`);
                      table.state.columns.forEach((column, index) => {
                        if (column.field === "accepted") {
                          table.state.columns[index].tableData.filterValue = "all";
                          column.tableData.initialValue = ["all"];
                        } else {
                          table.onFilterChange(index, null);
                          table.state.columns[index].tableData.filterValue = null;
                          column.tableData.initialValue = "";
                        }
                      });
                    }}
                  />
                  <div className={classes.exportToExcelWrapper}>
                    <Chip
                      variant="outlined"
                      style={{ marginLeft: 24, marginTop: 10 }}
                      avatar={this.state.loaded ? <GetApp style={{ padding: 5 }} /> : <ProgressCircular size={20} />}
                      label="Export data to Excel"
                      onClick={() => {
                        const startTime = Date.now();
                        this.setState({ loaded: false, filteredData: [] });
                        const currentSeasons = this.props.currentSeasons.map((season) => season.value);

                        const { search, sortingColumnsList, singleColumnFilterList } = getAndConvertULRParams(
                          this.props.location.search,
                          sortingPhraseHelper
                        );
                        const sortPath = getSortPath(sortingColumnsList);
                        const filterPath = getFilterPath(singleColumnFilterList);

                        axios
                          .get(`/export-orders-to-excel.json`, {
                            params: {
                              phrase: search,
                              sortPath: sortPath,
                              filterPath: filterPath,
                              currentSeasons: currentSeasons,
                            },
                            headers: { Authorization: `Bearer ${this.props.token}` },
                          })
                          .then((res) => {
                            console.log("Response time: " + (Date.now() - startTime) / 1000 + " s");
                            const ordersData = res.data.orders;
                            let filteredOrdersData = ordersData.map((order) => {
                              return {
                                id: order.id,
                                accepted:
                                  !order.accepted || order.accepted.trim() === ""
                                    ? null
                                    : order.accepted.includes("CANCEL")
                                    ? order.accepted
                                    : order.accepted.includes("HOLD")
                                    ? order.accepted
                                    : new Date(order.accepted),
                                number: order.number,
                                contact_name: order.contact,
                                company: order.company,
                                designer: order.designer_code,
                                season: order.season_code,
                                port: order.port,
                                shipment_date: order.shipment_date ? new Date(order.shipment_date) : null,
                                country: order.country,
                                payment: order.payment_term,
                                lc_number: order.lc_number,
                                total_quantity: order.total_quantity
                                  ? parseInt(order.total_quantity.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                total_value: order.total_value
                                  ? parseFloat(order.total_value.replace(/\s/g, "").replace(",", "."))
                                  : 0,
                                final_order: order.final_order,
                                proforma_document: order.proforma_document ? order.proforma_document.url : "",
                                barcodes_in_process: order.barcodes_in_process,
                              };
                            });

                            if (this.state.exportChosen) {
                              const exportColumns = this.state.columnsHidden.map((column) => {
                                if (column.field === "contact") column.field = "contact_name";
                                if (column.field === "payment_term") column.field = "payment";
                                if (column.field === "designer_code") column.field = "designer";
                                if (column.field === "season_id") column.field = "season";
                                return column.field;
                              });
                              filteredOrdersData = filteredOrdersData.map((row) => {
                                const toDelete = exportColumns;
                                const filtered = Object.keys(row)
                                  .filter((key) => toDelete.includes(key))
                                  .reduce((obj, key) => {
                                    obj[key] = row[key];
                                    return obj;
                                  }, {});

                                return filtered;
                              });
                            }

                            json2excel({
                              data: filteredOrdersData,
                              name: "orders_" + formatDate(Date.now()),
                              formateDate: "dd/mm/yyyy",
                            });

                            this.setState({ loaded: true, filteredData: this.state.data });
                          })
                          .catch((err) => {
                            catchResponse(err);
                          });
                      }}
                    />
                    <div className={classes.checkboxWrapper}>
                      <Checkbox
                        checked={this.state.exportChosen}
                        size={"small"}
                        onChange={() => this.setState({ exportChosen: !this.state.exportChosen })}
                        color="primary"
                      />
                      <span>Export only chosen columns</span>
                    </div>
                  </div>

                  {/*Generowanie tagu szukania*/}
                  {this.state.filterPhrase && this.state.filterPhrase.trim().length > 0 && (
                    <Chip
                      style={{ marginLeft: 24, marginTop: 10 }}
                      onDelete={() => {
                        this.goPage(
                          this.state.page,
                          this.state.limit,
                          "",
                          this.state.sortingColumnsList,
                          this.state.singleColumnFilterList
                        );
                        this.setState({ filterPhrase: "" });
                      }}
                      label={`search: ${this.state.filterPhrase}`}
                      color="primary"
                    />
                  )}

                  {/*Generowanie tagów filtrów kolumn*/}
                  {this.state.singleColumnFilterList.map((filter) => {
                    let value;
                    let key;
                    let icon = "";
                    if (filter.type === "dateType") value = filter.value.replace("BETWEEN", "").replace("AND", "-");
                    else if (filter.type === "text")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "select")
                      value = filter.value.replace("LIKE", "").replace("'%", "").replace("%'", "");
                    else if (filter.type === "multiSelect")
                      value = filter.value
                        .split("LIKE")
                        .join("")
                        .split("'%")
                        .join("")
                        .split("%'")
                        .join("")
                        .split(`OR ${filter.column.path}`)
                        .join(",");
                    else if (filter.type === "number") value = filter.value.replace("=", "");
                    else if (filter.type === "boolean") {
                      if (filter.value.includes("true")) {
                        value = "checked";
                        icon = <Check />;
                      } else {
                        value = "unchecked";
                        icon = <Close />;
                      }
                    }

                    /*WYJĄTKI*/
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `="" OR ${filter.column.path} IS NULL`
                    )
                      value = "UNADDED";
                    if (
                      filter.column.key === "proforma_document" &&
                      filter.value === `<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`
                    )
                      value = "ADDED";
                    filter.column.key === "payment_ref" ? (key = "LC NUMBER") : (key = filter.column.key);
                    if (filter.column.key === "payment_term_id") {
                      let currentValue = "";
                      const column = this.state.columns.filter((item) => item.field === "payment_term_id")[0].lookup;
                      const paymnetValue = value.replace(/\s/g, "").split(",");
                      paymnetValue.forEach((item, index) =>
                        index + 1 === paymnetValue.length
                          ? (currentValue += `${column[parseInt(item)]}`)
                          : (currentValue += `${column[parseInt(item)]}, `)
                      );
                      value = currentValue;
                      key = "PAYMENT";
                    }

                    /*MULTISELECT ACCEPTED WYJĄTKI*/
                    if (filter.column.key === "accepted") {
                      value = "";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`LIKE "%%" OR ${filter.column.path} IS NULL`)
                      )
                        value += "ALL ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`="" OR ${filter.column.path} IS NULL`)
                      )
                        value += "EMPTY ";
                      if (
                        filter.column.key === "accepted" &&
                        filter.value.includes(`<> '' AND ${filter.column.path} IS NOT NULL AND ${filter.column.path}`)
                      )
                        value += "ACCEPTED ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%hold%"`)) value += "HOLD ";
                      if (filter.column.key === "accepted" && filter.value.includes(`LIKE "%cancel%"`))
                        value += "CANCEL ";
                    }

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const singleColumnFilterList = this.state.singleColumnFilterList.filter(
                            (item) => item.column.key !== filter.column.key
                          );
                          this.goPage(
                            this.state.page,
                            this.state.limit,
                            this.state.filterPhrase,
                            this.state.sortingColumnsList,
                            singleColumnFilterList
                          );
                          this.setState({ singleColumnFilterList: singleColumnFilterList });
                          const index = table.state.columns.findIndex((item) => item.field === filter.column.key);
                          if (index !== -1) {
                            if (table.state.columns[index].field === "accepted") {
                              table.state.columns[index].tableData.initialValue = ["all"];
                            } else {
                              table.state.columns[index].tableData.initialValue = "";
                              table.onFilterChange(index, null);
                            }
                          }
                        }}
                        label={`${key.replace("_", " ").toUpperCase()}:  ${value.toUpperCase()}`}
                        icon={icon}
                        variant="outlined"
                      />
                    );
                  })}

                  {/*Generowanie tagów sortowania*/}
                  {this.state.sortingColumnsList.map((column) => {
                    let key;
                    let icon = "";
                    if (column.order === "ASC") icon = <ArrowDownward />;
                    if (column.order === "DESC") icon = <ArrowUpward />;

                    /*WYJĄTKI*/
                    column.sortBy.key === "payment_ref" ? (key = "LC NUMBER") : (key = column.sortBy.key);

                    return (
                      <Chip
                        style={{ marginLeft: 24, marginTop: 10 }}
                        onDelete={() => {
                          const sortingColumnsList = this.state.sortingColumnsList.filter(
                            (item) => item.sortBy.key !== column.sortBy.key
                          );
                          this.goPage(
                            this.state.page,
                            this.state.limit,
                            this.state.filterPhrase,
                            sortingColumnsList,
                            this.state.singleColumnFilterList
                          );
                          this.setState({ sortingColumnsList: sortingColumnsList });
                        }}
                        label={`${key.toUpperCase().replace("_", " ")}`}
                        icon={icon}
                      />
                    );
                  })}

                  <div className={classes.ColumnsMultiSelectWrapper}>
                    <ColumnsMultiSelect
                      onOpen={() =>
                        !this.state.blockedSaveColumnsButton && this.setState({ blockedSaveColumnsButton: true })
                      }
                      value={this.state.columnsHidden
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "orders",
                          };
                        })}
                      options={Columns.ordersColumns
                        .filter((column) => column.field !== "order_id" && column.field !== "id")
                        .map((column) => {
                          return {
                            label: column.title,
                            value: column.field,
                            table: "orders",
                          };
                        })}
                      onChangeColumns={async (columns) => {
                        await this.setState({ columnsHidden: [], currentStateColumns: null });
                        await this.setSelectedColumns(columns);
                        this.setState({ blockedSaveColumnsButton: false });
                        this.setSortingForColumns();
                      }}
                    />
                    <Button
                      disabled={this.state.blockedSaveColumnsButton}
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.setItem(
                            `user_orders_${this.props.user_id}`,
                            JSON.stringify(this.state.columnsHidden)
                          );
                          toast.success("The selected columns have been saved!");
                          this.setState({ blockedSaveColumnsButton: true });
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      variant={"outlined"}
                    >
                      SAVE
                    </Button>
                    <Button
                      onClick={async () => {
                        if (this.props.user_id) {
                          window.localStorage.removeItem(`user_orders_${this.props.user_id}`);
                          await this.setState({
                            columnsHidden: this.state.columns,
                            currentStateColumns: null,
                          });
                          toast.success("Column settings have been removed!");
                          this.setSortingForColumns();
                        } else {
                          toast.error("You must be logged in!");
                        }
                      }}
                      color={"secondary"}
                      variant={"outlined"}
                    >
                      RESET
                    </Button>
                  </div>

                  <div className={classes.SearchWrapper}>
                    <SearchInput filterPhrase={this.state.filterPhrase} onChange={this.handleSerach} />
                  </div>

                  <Table style={{ width: "50%" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Summary</TableCell>
                        <TableCell align="right">Final orders</TableCell>
                        <TableCell align="right">Quantity</TableCell>
                        <TableCell align="right">Value</TableCell>
                        <TableCell align="right">Quantity Percentange</TableCell>
                        <TableCell align="right">Value Percentange</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row" style={{ fontWeight: "bold", width: "20%" }}>
                          Total
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterQuantity.format(this.state.totalFinalOrders)}/{this.state.totalRow}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterQuantity.format(this.state.totalQuantity)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {formatterCurrency.format(this.state.totalValue)}
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.quantityPercentage}`.replace(".", ",")} %
                        </TableCell>
                        <TableCell align="right" style={{ width: "20%" }}>
                          {`${this.state.valuePercentage}`.replace(".", ",")} %
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              );
            },
          }}
          editable={this.state.editable}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user_id: state.auth.user_id,
    ip_address: state.auth.ip_address,
    token: state.auth.token,
    role: state.auth.role,
    currentSeasons: state.systemInfo.currentSeasons,
  };
};

export default connect(mapStateToProps)(Orders);
